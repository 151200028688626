<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb :crumbs='generateBreadCrumb()'></route-bread-crumb>
          </nav>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-overlay :show="isBusy">
        <b-card>
          <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <b-form
              class="needs-validation"
              @submit.prevent="handleSubmit(itemFormSubmit)"
              novalidate
            >
              <h6 class="heading-small text-muted mb-4">Content</h6>

              <div class="">
                <b-row>

                  <b-col lg="12">
                    <media-input
                      name="Image"
                      label="Image"
                      v-model="itemForm.media_id"
                    >
                    </media-input>
                  </b-col>

                  <b-col lg="6">
                    <base-input
                      type="text"
                      :name="'Fullname - English'"
                      label="Fullname - English"
                      :required="true"
                      placeholder="Fullname - English"
                      v-model="itemForm.fullname.en"
                    >
                    </base-input>
                  </b-col>
                  <b-col lg="6">
                    <base-input
                      type="text"
                      :name="'Fullname - Arabic'"
                      label="Fullname - Arabic"
                      :required="true"
                      placeholder="Fullname - Arabic"
                      v-model="itemForm.fullname.ar"
                    >
                    </base-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6">
                    <html-editor
                      :name="'Description - English'"
                      label="Description - English"
                      :rows="'5'"
                      placeholder="Description - English"
                      :type="'html'"
                      v-model="itemForm.description.en"
                    >
                    </html-editor>
                  </b-col>

                  <b-col lg="6">
                    <html-editor
                      :name="'Description - Arabic'"
                      label="Description - Arabic"
                      :rows="'5'"
                      placeholder="Description - Arabic"
                      :type="'textarea'"
                      v-model="itemForm.description.ar"
                    >
                    </html-editor>
                  </b-col>

                  <b-col lg="6">
                    <base-multi-select
                    name="Jobs"
                    placeholder="Select jobs"
                    :required="true"
                      v-model="itemForm.jobs"
                      :options="jobs"
                      :multiple="true"
                      
                      label="Jobs"
                      track-by="value"
                    >
                    </base-multi-select>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group>
                      <label class="form-control-label">Visible</label>
                      <b-form-checkbox class="mb-3" v-model="itemForm.visible">
                        Hide from mobile app
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>

                
              </div>

              <base-button type="primary" native-type="submit" class="my-4">
                {{ isUpdate() ? "Update Information" : "Create" }}
              </base-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-overlay>
    </b-container>
  </div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
/*
model from backend
export interface PersonnelModel {
  fullname: {en: string, ar: string};
  jobs?: string[];
  description?: string
  visible?: boolean;
  deleted?: boolean;
  last_updated_by: string;
  last_updated: Date;
} */

import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb.vue";
import MediaInput from "@/components/Inputs/MediaInput.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor.vue";
import * as personnelRepo from "@/api-repo/personnel";

import { handleError, successMessage } from "@/util/error-handler";
import BaseMultiSelect from "@/components/Inputs/BaseMultiSelect.vue";
export default {
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
    MediaInput,
    HtmlEditor,
    BaseMultiSelect,
  },
  props: ["type"],
  data() {
    return {
      isBusy: false,
      jobs: [
        { name: "Advisor", value: "advisor" },
         //{name: 'Doctor', value: 'doctor'}
      ],
     
      itemForm: {
        jobs: null,
        fullname: { en: null, ar: null },
        description: { en: null, ar: null },
        visible: null,
      },
    };
  },
  created() {
    this.getInitData();
    if (this.isUpdate()) {
      this.getData();
    }
  },
  methods: {
generateBreadCrumb() {
              return [{label: "Personnel", route: "Personnel"},{label: this.isUpdate() ? this.itemForm.fullname.en : "Add New Personnel"}];

      },
    getInitData: async function () {
      //let res = await roleRepo.getAll(this.userStore.state.token);
      //this.roles = res.data.data;
      //console.log(this.roles);
    },
    getData: async function () {
      try {
        this.isBusy = true;
        let result = await personnelRepo.getById(
          this.$route.params.id,
          this.userStore.state.token
        );
        this.itemForm.media_id =  result.data.data.media_id;
        this.itemForm.fullname = result.data.data.fullname || {en: null, ar: null};
        this.itemForm.description = result.data.data.description  || {en: null, ar: null};
        this.itemForm.visible = result.data.data.visible || false;
        this.itemForm.jobs = this.mapJobs(result.data.data.jobs || []);
      } catch (e) {
        handleError(e);
      } finally {
        this.isBusy = false;
      }
    },

    isUpdate: function () {
      return this.$route.params.id != null;
    },

    mapJobs(jobs) {
      if (!jobs) {
        return [];
      }
      return this.jobs.filter((e) => jobs.find((job) => e.value === job));
    },

    itemFormSubmit: async function () {
      const isValid = await this.$refs.formValidator.validate();
      if (isValid) {
        //this.itemForm.visible = this.itemForm.visible;
        const objectForSubmission = {
          ...this.itemForm,
          jobs: this.itemForm.jobs
            ? this.itemForm.jobs.map((e) => e.value)
            : null,
        };

        if (!this.isUpdate()) {
          try {
            this.isBusy = true;
            await personnelRepo.create(
              objectForSubmission,
              this.userStore.state.token
            );
            successMessage("Item created successfully");
            this.$router.replace({ path: "/staff/personnel/" });
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        } else {
          try {
            console.log(objectForSubmission)
            this.isBusy = true;
            await personnelRepo.update(
              this.$route.params.id,
              objectForSubmission,
              this.userStore.state.token
            );
            successMessage("Item updated successfully");
          } catch (e) {
            handleError(e);
          } finally {
            this.isBusy = false;
          }
        }
      }
    },
  },
};
</script>
